import * as React from 'react'
import styled from '@emotion/styled'
import { IoMdCall } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import { FormattedMessage } from 'react-intl';
import LocalizedLink from "./int/LocalizedLink";

import {Link} from "gatsby";
import {fonts} from "../styles/variables";


const StyledDiv = styled.div`

  width: 50%;
text-align:center;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:2em;
font-family: "Theano Didot", Regular;
font-size: 1.22em;
letter-spacing: 1px;
margin-left:auto;
margin-right:auto;



`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 180%;
text-align: right;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:100%;



`;


const StyledPhoneIcon = styled(IoMdCall)`
width:26px;
height:26px;
transition: all .7s ease;
margin-right:5px;
opacity:0;
display:none;


`



const StyledMailIcon = styled(IoMdMail)`
width:26px;
height:26px;
margin-top:2px;
transition: all .7s ease;
margin-right:5px;
opacity:0;
display:none;



`

const ABold = styled.a`
font-family: "Theano Didot", Regular;
font-weight:400;
line-height: 1.5em;
color:rgb(68, 68, 68) ;
font-size:1.6em;
opacity:1;
transition:all .7s ease;

:hover{
opacity:0.7;}

:hover {
${StyledMailIcon}{
//display:block;
//opacity:0.7;
}

${StyledPhoneIcon}{
//display:block;
//opacity:0.7;
}
}



`;

const PBold = styled.p`
font-family: "Theano Didot", Regular;
font-weight:bold;
line-height: 1.5em;
color:rgb(68, 68, 68);
margin-bottom:0px;
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
font-size:100%;
line-height: 1.4em;
color:rgb(68, 68, 68);
margin-bottom:0px;

`;

const PItalic = styled.p`
font-family: "Theano Didot", Regular;
font-size:180%;
font-style:italic;
line-height: 1.4em;
color:rgb(68, 68, 68);
margin-bottom:0px;

`;

const Div = styled.div`
margin-bottom:10px;
width:100%;
display:;flex;
justify-content:center;
align-items:center;
`


const ContinueButton = styled.div`
 cursor:pointer;
font-weight:700;
width:160px;
margin-bottom:10px;
margin-top:20px;
height:48px;
 display: flex;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:21px;
margin-left:auto;
margin-right:auto;
font-family:${fonts.button};
letter-spacing:2px;


:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
  color: #fff;
}

:hover:before {
  width: 100%;
}


   @media screen and (max-width: 1295px) {
z-index:999999;
}

`

const StyledLinke = styled(LocalizedLink)`
color:#188cec;
font-weight:bold;

`



interface VoucherSuccessProps {
  className?: string
}

const VoucherSuccessDiv: React.FC<VoucherSuccessProps> = ({ }) => (
  <StyledDiv>

    <PItalic > Vielen Dank! </PItalic>


  </StyledDiv>

)

export default VoucherSuccessDiv
